/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AllauthPartialUser from './AllauthPartialUser';

/**
 * The CheckLinkResetForgottenPasswordOutData model module.
 * @module model/CheckLinkResetForgottenPasswordOutData
 * @version 1.0.0
 */
class CheckLinkResetForgottenPasswordOutData {
    /**
     * Constructs a new <code>CheckLinkResetForgottenPasswordOutData</code>.
     * @alias module:model/CheckLinkResetForgottenPasswordOutData
     * @param user {module:model/AllauthPartialUser} 
     */
    constructor(user) { 
        
        CheckLinkResetForgottenPasswordOutData.initialize(this, user);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, user) { 
        obj['user'] = user;
    }

    /**
     * Constructs a <code>CheckLinkResetForgottenPasswordOutData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CheckLinkResetForgottenPasswordOutData} obj Optional instance to populate.
     * @return {module:model/CheckLinkResetForgottenPasswordOutData} The populated <code>CheckLinkResetForgottenPasswordOutData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CheckLinkResetForgottenPasswordOutData();

            if (data.hasOwnProperty('user')) {
                obj['user'] = AllauthPartialUser.constructFromObject(data['user']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>CheckLinkResetForgottenPasswordOutData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CheckLinkResetForgottenPasswordOutData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of CheckLinkResetForgottenPasswordOutData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `user`
        if (data['user']) { // data not null
          AllauthPartialUser.validateJSON(data['user']);
        }

        return true;
    }


}

CheckLinkResetForgottenPasswordOutData.RequiredProperties = ["user"];

/**
 * @member {module:model/AllauthPartialUser} user
 */
CheckLinkResetForgottenPasswordOutData.prototype['user'] = undefined;






export default CheckLinkResetForgottenPasswordOutData;


/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AllauthOutBadRequestErrors from './AllauthOutBadRequestErrors';

/**
 * The AllauthOutBadRequest model module.
 * @module model/AllauthOutBadRequest
 * @version 1.0.0
 */
class AllauthOutBadRequest {
    /**
     * Constructs a new <code>AllauthOutBadRequest</code>.
     * @alias module:model/AllauthOutBadRequest
     * @param status {Number} 
     * @param errors {Array.<module:model/AllauthOutBadRequestErrors>} 
     */
    constructor(status, errors) { 
        
        AllauthOutBadRequest.initialize(this, status, errors);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, status, errors) { 
        obj['status'] = status;
        obj['errors'] = errors;
    }

    /**
     * Constructs a <code>AllauthOutBadRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AllauthOutBadRequest} obj Optional instance to populate.
     * @return {module:model/AllauthOutBadRequest} The populated <code>AllauthOutBadRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AllauthOutBadRequest();

            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'Number');
            }
            if (data.hasOwnProperty('errors')) {
                obj['errors'] = ApiClient.convertToType(data['errors'], [AllauthOutBadRequestErrors]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AllauthOutBadRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AllauthOutBadRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of AllauthOutBadRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['errors']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['errors'])) {
                throw new Error("Expected the field `errors` to be an array in the JSON data but got " + data['errors']);
            }
            // validate the optional field `errors` (array)
            for (const item of data['errors']) {
                AllauthOutBadRequestErrors.validateJSON(item);
            };
        }

        return true;
    }


}

AllauthOutBadRequest.RequiredProperties = ["status", "errors"];

/**
 * @member {Number} status
 */
AllauthOutBadRequest.prototype['status'] = undefined;

/**
 * @member {Array.<module:model/AllauthOutBadRequestErrors>} errors
 */
AllauthOutBadRequest.prototype['errors'] = undefined;






export default AllauthOutBadRequest;


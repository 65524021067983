/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AllauthPartialUser model module.
 * @module model/AllauthPartialUser
 * @version 1.0.0
 */
class AllauthPartialUser {
    /**
     * Constructs a new <code>AllauthPartialUser</code>.
     * @alias module:model/AllauthPartialUser
     * @param id {String} 
     * @param display {String} 
     * @param hasUsablePassword {Boolean} 
     * @param email {String} 
     * @param username {String} 
     */
    constructor(id, display, hasUsablePassword, email, username) { 
        
        AllauthPartialUser.initialize(this, id, display, hasUsablePassword, email, username);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, display, hasUsablePassword, email, username) { 
        obj['id'] = id;
        obj['display'] = display;
        obj['has_usable_password'] = hasUsablePassword;
        obj['email'] = email;
        obj['username'] = username;
    }

    /**
     * Constructs a <code>AllauthPartialUser</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AllauthPartialUser} obj Optional instance to populate.
     * @return {module:model/AllauthPartialUser} The populated <code>AllauthPartialUser</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AllauthPartialUser();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('display')) {
                obj['display'] = ApiClient.convertToType(data['display'], 'String');
            }
            if (data.hasOwnProperty('has_usable_password')) {
                obj['has_usable_password'] = ApiClient.convertToType(data['has_usable_password'], 'Boolean');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AllauthPartialUser</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AllauthPartialUser</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of AllauthPartialUser.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['id'] && !(typeof data['id'] === 'string' || data['id'] instanceof String)) {
            throw new Error("Expected the field `id` to be a primitive type in the JSON string but got " + data['id']);
        }
        // ensure the json data is a string
        if (data['display'] && !(typeof data['display'] === 'string' || data['display'] instanceof String)) {
            throw new Error("Expected the field `display` to be a primitive type in the JSON string but got " + data['display']);
        }
        // ensure the json data is a string
        if (data['email'] && !(typeof data['email'] === 'string' || data['email'] instanceof String)) {
            throw new Error("Expected the field `email` to be a primitive type in the JSON string but got " + data['email']);
        }
        // ensure the json data is a string
        if (data['username'] && !(typeof data['username'] === 'string' || data['username'] instanceof String)) {
            throw new Error("Expected the field `username` to be a primitive type in the JSON string but got " + data['username']);
        }

        return true;
    }


}

AllauthPartialUser.RequiredProperties = ["id", "display", "has_usable_password", "email", "username"];

/**
 * @member {String} id
 */
AllauthPartialUser.prototype['id'] = undefined;

/**
 * @member {String} display
 */
AllauthPartialUser.prototype['display'] = undefined;

/**
 * @member {Boolean} has_usable_password
 */
AllauthPartialUser.prototype['has_usable_password'] = undefined;

/**
 * @member {String} email
 */
AllauthPartialUser.prototype['email'] = undefined;

/**
 * @member {String} username
 */
AllauthPartialUser.prototype['username'] = undefined;






export default AllauthPartialUser;


/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import LoginOutSuccessData from './LoginOutSuccessData';
import LoginOutSuccessMeta from './LoginOutSuccessMeta';

/**
 * The LoginOutSuccess model module.
 * @module model/LoginOutSuccess
 * @version 1.0.0
 */
class LoginOutSuccess {
    /**
     * Constructs a new <code>LoginOutSuccess</code>.
     * @alias module:model/LoginOutSuccess
     * @param status {Number} 
     * @param data {module:model/LoginOutSuccessData} 
     * @param meta {module:model/LoginOutSuccessMeta} 
     */
    constructor(status, data, meta) { 
        
        LoginOutSuccess.initialize(this, status, data, meta);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, status, data, meta) { 
        obj['status'] = status;
        obj['data'] = data;
        obj['meta'] = meta;
    }

    /**
     * Constructs a <code>LoginOutSuccess</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LoginOutSuccess} obj Optional instance to populate.
     * @return {module:model/LoginOutSuccess} The populated <code>LoginOutSuccess</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoginOutSuccess();

            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'Number');
            }
            if (data.hasOwnProperty('data')) {
                obj['data'] = LoginOutSuccessData.constructFromObject(data['data']);
            }
            if (data.hasOwnProperty('meta')) {
                obj['meta'] = LoginOutSuccessMeta.constructFromObject(data['meta']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>LoginOutSuccess</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>LoginOutSuccess</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of LoginOutSuccess.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `data`
        if (data['data']) { // data not null
          LoginOutSuccessData.validateJSON(data['data']);
        }
        // validate the optional field `meta`
        if (data['meta']) { // data not null
          LoginOutSuccessMeta.validateJSON(data['meta']);
        }

        return true;
    }


}

LoginOutSuccess.RequiredProperties = ["status", "data", "meta"];

/**
 * @member {Number} status
 */
LoginOutSuccess.prototype['status'] = undefined;

/**
 * @member {module:model/LoginOutSuccessData} data
 */
LoginOutSuccess.prototype['data'] = undefined;

/**
 * @member {module:model/LoginOutSuccessMeta} meta
 */
LoginOutSuccess.prototype['meta'] = undefined;






export default LoginOutSuccess;


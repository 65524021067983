/**
 * Picsellia API
 * Picsellia API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AllauthOutUnauthenticatedDataFlow from './AllauthOutUnauthenticatedDataFlow';

/**
 * The AllauthOutUnauthenticatedData model module.
 * @module model/AllauthOutUnauthenticatedData
 * @version 1.0.0
 */
class AllauthOutUnauthenticatedData {
    /**
     * Constructs a new <code>AllauthOutUnauthenticatedData</code>.
     * @alias module:model/AllauthOutUnauthenticatedData
     * @param flows {Array.<module:model/AllauthOutUnauthenticatedDataFlow>} 
     */
    constructor(flows) { 
        
        AllauthOutUnauthenticatedData.initialize(this, flows);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, flows) { 
        obj['flows'] = flows;
    }

    /**
     * Constructs a <code>AllauthOutUnauthenticatedData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AllauthOutUnauthenticatedData} obj Optional instance to populate.
     * @return {module:model/AllauthOutUnauthenticatedData} The populated <code>AllauthOutUnauthenticatedData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AllauthOutUnauthenticatedData();

            if (data.hasOwnProperty('flows')) {
                obj['flows'] = ApiClient.convertToType(data['flows'], [AllauthOutUnauthenticatedDataFlow]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>AllauthOutUnauthenticatedData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>AllauthOutUnauthenticatedData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of AllauthOutUnauthenticatedData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['flows']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['flows'])) {
                throw new Error("Expected the field `flows` to be an array in the JSON data but got " + data['flows']);
            }
            // validate the optional field `flows` (array)
            for (const item of data['flows']) {
                AllauthOutUnauthenticatedDataFlow.validateJSON(item);
            };
        }

        return true;
    }


}

AllauthOutUnauthenticatedData.RequiredProperties = ["flows"];

/**
 * @member {Array.<module:model/AllauthOutUnauthenticatedDataFlow>} flows
 */
AllauthOutUnauthenticatedData.prototype['flows'] = undefined;






export default AllauthOutUnauthenticatedData;

